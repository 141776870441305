import {
  singlePageDataFetcher,
  SinglePageTemplate,
} from "components/templates/SinglePage";

export default SinglePageTemplate;

export async function getServerSideProps(context) {
  return await singlePageDataFetcher(context);
}
